// Styles
.fit-contents {
  display: block !important;
}

.none {
  display: none !important;
}

.table-mock {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 1rem;
  border-radius: 8px;
  h5 {
    font-size: 14px;
    text-align: center;
  }
}

// Main styles

@import './app-modern.scss';

// Hyper Icons
@import './icons.scss';
